<template>
    <div>
        <surround-loader :is-loading="isLoading">
            <div class="p-4">
                <h2 class="mb-4">Privacy agreement and contract</h2>
                <div v-if="$store.state.user.organisation.setup_contract_at == null">
                    <div class="alert alert-info">
                        Please read the below Terms and Conditions carefully
                    </div>

                    <div class="border rounded overflow-auto shadow-sm w-100 position-relative" style="padding-bottom: 70%;">
                        <terms-setup class="position-absolute" style="top:0; right:0; left: 0;"></terms-setup>
                    </div>


                    <div class="mt-4">
                        <b-form-checkbox
                                v-model="terms"
                                :value="1"
                                unchecked-value="0"
                        >By ticking this box you are agreeing to the Terms and Conditions
                        </b-form-checkbox>
                    </div>
                </div>
                <div v-else class="alert alert-success font-weight-bold d-flex align-items-center">
                    <b-icon-check class="mr-2"></b-icon-check>
                    Terms and conditions were agreed to on {{ dateToNice($store.state.user.organisation.setup_contract_at) }}
                </div>
            </div>
            <div class="p-4  d-flex justify-content-between">
                <b-button :to="{name:'setup_users_setup'}" variant="outline-secondary">
                    Back
                </b-button>
                <b-button :disabled="terms !== 1 && $store.state.user.organisation.setup_contract_at == null" :to="{name:'setup_payment'}" @click="saveForm"
                          variant="success">
                    Proceed to payment
                </b-button>
            </div>
        </surround-loader>
    </div>
</template>

<script>
    import Vue from "vue";
    import {getResource, saveResource} from "../../modules/api/methods";
    import {containsErrors} from "../../modules/helpers/helpers";
    import {baseUrl, organisationCurrent, organisationSetupContract} from "../../modules/api/endpoints";
    import SurroundLoader from "../SurroundLoader";
    import moment from 'moment'
    import TermsSetup from "./TermsSetup";

    export default {
        name: 'setup_contract',
        data: function () {
            return {
                isLoading: false,
                errors: {},
                terms: null,
            }
        },
        created() {
            this.loadModel();
        },
        methods: {
            dateToNice(date) {
                return new moment(date).format("DD/MM/YYYY");
            },
            loadModel() {
                let vm = this;
                vm.isLoading = true;
                getResource(organisationCurrent).then((resp) => {
                    vm.$store.commit('updateOrganisation', resp.data.success.data);
                }).catch((ex) => {
                    console.log(ex);
                }).finally(function () {
                    vm.isLoading = false;
                })
            },
            saveForm() {
                if(this.$store.state.user.organisation.setup_contract_at != null) {
                    vm.$router.push({name: 'setup_payment'}).catch(error => {});
                    return;
                }
                var vm = this;
                vm.errors = {};
                vm.isLoading = true;
                getResource(organisationSetupContract).then(function (resp) {
                    //UPDATE THE ORG TO ALLOW TO PROCEED TO NEXT STEP
                    console.log(resp.data.success.data);
                    vm.$store.dispatch('updateOrganisation', resp.data.success.data).then(() => {
                        vm.$router.push({name: 'setup_payment'}).catch(error => {});
                    });
                }).catch(function (err) {
                    console.log(err);
                    if (containsErrors(err)) {
                        vm.errors = err.data.errors;
                    }
                }).finally(function () {
                    vm.isLoading = false;
                })
            },
        },
        components: {TermsSetup, SurroundLoader},
    }
</script>

