<template>
    <div class="p-5">
        <img class="mb-5" src="../../assets/logo.png" style="width: 250px"/>

        <h4 class="mb-4">AGREEMENT TERMS & CONDITIONS</h4>

        <h6 class="text-primary font-weight-bold mb-3">OVERVIEW</h6>

        <b-table class="mb-5" striped bordered hover responsive :items="overview" :fields="overviewFields"></b-table>


        <h6 class="text-primary font-weight-bold mb-3">RIGHT TO WORK PAYG PAYMENT TERMS</h6>
        <p v-if="this.$store.getters.isWorkspaceAccount" class="mb-5">All optional and charged as taken (Access Right to
            Work is a Pay As You Go Service, you only pay
            for Right to
            Work checks you have initiated). Charges are invoiced monthly in arrears. Payment is due within 30 days of
            receiving the invoice.
        </p>
        <p v-else>All optional and charged as taken (Access Right to Work is a Pay As You Go Service, you only pay for
            Right to Work checks you have initiated). Payment is handled using Stripe as the payment gateway. Stripe
            Privacy Policy and Terms are available here: <a href="https://stripe.com/gb/privacy" target="_blank">https://stripe.com/gb/privacy</a>.
            You may switch to an invoicing
            method if you prefer once your account has been created by contacting support (details below).</p>

        <h6 class="text-primary font-weight-bold mb-3">STATEMENT OF WORK</h6>

        <b-table class="mb-5" striped bordered hover responsive :items="work" :fields="workFields"></b-table>

        <h6 class="text-primary font-weight-bold mb-3">TABLE OF RIGHT TO WORK CHARGES</h6>
        <surround-loader :is-loading="isLoading">
            <b-table class="" striped bordered hover responsive :items="charges" :fields="chargesFields"></b-table>
        </surround-loader>

        <p class="font-italic mb-5">Prices will be charged in GBP (£). Prices shown exclude VAT.</p>

        <h6 class="text-primary font-weight-bold mb-3">SOFTWARE</h6>
        <p class="mb-5">The parties agree that the following additional terms and conditions apply to the provision of
            Right to Work software provided under this Statement of Work:
            <a href="https://www.theaccessgroup.com/additionalscreeningtandcs" target="_blank">https://www.theaccessgroup.com/additionalscreeningtandcs</a>
        </p>

        <h6 class="text-primary font-weight-bold mb-3">TERMS AND CONDITIONS</h6>
        <p class="mb-5">You agree to the full Terms and Conditions which are set out at:
            <a href="https://www.theaccessgroup.com/tandcs" target="_blank">https://www.theaccessgroup.com/tandcs</a>
        </p>

        <h6 class="text-primary font-weight-bold mb-3">PRIVACY POLICY</h6>
        <p v-if="this.$store.getters.isWorkspaceAccount" class="mb-5">We handle your data safely and securely. You can
            find full details of our Privacy Policy here:
            <a href="https://www.theaccessgroup.com/privacy-notice/" target="_blank">https://www.theaccessgroup.com/privacy-notice/</a>
        </p>
        <p v-else>We handle your data safely and securely and all of our connections with Stripe are over https and
            secure. You can find full details of our Privacy Policy here:
            <a href="https://www.theaccessgroup.com/privacy-notice/" target="_blank">https://www.theaccessgroup.com/privacy-notice/</a>
        </p>

        <h6 class="text-primary font-weight-bold mb-3">CUSTOMER SERVICE</h6>
        <p class="mb-5">
            If you have any queries please contact our support team.<br/>
            Phone: 0116 248 8504<br/>
            E-mail: <a href="mailto:screening.support@safecomputing.co.uk">screening.support@safecomputing.co.uk</a>
        </p>

        <div class="row">
            <div class="col-6 text-left" style="font-size: 8px;">
                Access UK Ltd. The Old School, Stratford St. Mary, Colchester, Essex, CO7 6LZ, United Kingdom
            </div>
            <div class="col-6 text-right" style="font-size: 8px;">
                Registered in England and Wales no. 2343760 This Document is the property of Access UK © Copyright 2018
                Access UK Ltd | All rights reserved
            </div>
        </div>

    </div>
</template>

<script>
    import Vue from "vue";
    import {getResource} from "../../modules/api/methods";
    import {costModel} from "../../modules/api/endpoints";
    import SurroundLoader from "../SurroundLoader";

    export default {
        name: 'TermsSetup',
        created() {
            this.loadCostModel();
        },
        data: function () {
            return {
                isLoading: false,
                cost_model: {
                    application_cost: 2.00,
                    doc_check_cost: 3.50,
                    frequency: 'monthly',
                },
                overviewFields: [
                    {
                        key: 'software',
                        label: 'Software/SaaS module',
                    }, {
                        key: 'license',
                        label: 'License term',
                    }, {
                        key: 'usage',
                        label: 'Permitted usage',
                    }, {
                        key: 'service_fees',
                        label: 'Services fees',
                    }, {
                        key: 'saas_fees',
                        label: 'Fees SaaS',
                    },
                ],
                overview: [
                    {
                        software: "Access Screening Right to Work Mobile App",
                        license: "N/A (Pay As You Go)",
                        usage: "N/A",
                        service_fees: "Zero",
                        saas_fees: "Variable consumed Services as detailed below.",
                    }
                ],
                workFields: [
                    {
                        key: 'software',
                        label: 'Software/SaaS module',
                    }, {
                        key: 'license',
                        label: 'License term',
                    }, {
                        key: 'usage',
                        label: 'Permitted usage',
                    }, {
                        key: 'saas_fees',
                        label: 'Fees SaaS',
                    },
                ],
                work: [
                    {
                        software: "Access Screening Right to Work Mobile App",
                        license: "N/A (Pay As You Go)",
                        usage: "N/A",
                        saas_fees: "Variable consumed Services as detailed below.",
                    }
                ],
                chargesFields: [
                    {
                        key: 'check',
                        label: 'Check',
                    }, {
                        key: 'cost',
                        label: 'Cost per Check',
                    }
                ],
                charges: [
                    {
                        check: "Right to Work Application",
                        cost: "£2.00",
                    }, {
                        check: "Right to Work Document Check",
                        cost: "£3.50",
                    },
                ]
            }
        },
        methods: {
            loadCostModel() {
                this.isLoading = true;
                getResource(costModel).then((resp) => {
                    this.cost_model = resp.data.success.data;
                    this.charges = [
                        {
                            check: "Right to Work Application",
                            cost: "£" + this.cost_model.application_cost,
                        }, {
                            check: "Right to Work Document Check",
                            cost: "£" + this.cost_model.doc_check_cost,
                        },
                    ];
                    this.isLoading = false;
                }).catch((ex) => {
                    console.log(ex);
                    this.isLoading = false;
                });
            },
        },
        components: {SurroundLoader},
    }
</script>

